import {
  FooterWrapper,
  FooterRefSection,
  RefTitle,
  RefText,
  RefItem,
  Divider,
  FooterContact,
  ImgContact,
} from './Styled'
import LogoFooter from 'assets/images/icon/CasperPunks_Logo_footer.png'
import DiscordLogo from 'assets/images/icon/Discord.png'
import TwitterLogo from 'assets/images/icon/Twitter.png'
import TelegramLogo from 'assets/images/icon/Telegram.png'
import MLogo from 'assets/images/icon/M.png'
import Button from 'components/Button'

function Footer(): JSX.Element {
  return (
    <FooterWrapper>
      <FooterRefSection>
        <img src={LogoFooter} />
        {/* <div className="d-lg-flex flex-row d-md-none" style={{ gap: '7rem' }}>
          <RefItem>
            <RefTitle>RESOURCES</RefTitle>
            <ul className="d-flex flex-column gap-1">
              <RefText>Developer API</RefText>
              <RefText>Github</RefText>
              <RefText>Support</RefText>
            </ul>
          </RefItem>
          <RefItem>
            <RefTitle>CORPORATE</RefTitle>
            <ul className="d-flex flex-column gap-1">
              <RefText>About Us</RefText>
              <RefText>Terms of Service</RefText>
              <RefText>Privacy Policy</RefText>
              <RefText>Governance</RefText>
            </ul>
          </RefItem>
          <RefItem>
            <RefTitle>STAKING</RefTitle>
            <ul className="d-flex flex-column gap-1">
              <RefText>Ethereum Staking</RefText>
              <RefText>Ethereum Staking</RefText>
              <RefText>Ethereum Staking</RefText>
              <RefText>Ethereum Staking</RefText>
              <RefText>Ethereum Staking</RefText>
              <RefText>Ethereum Staking</RefText>
              <RefText>Ethereum Staking</RefText>
              <RefText>Ethereum Staking</RefText>
              <RefText>Ethereum Staking</RefText>
            </ul>
          </RefItem>
        </div> */}
      </FooterRefSection>
      <Divider />
      <FooterContact>
        <RefText>Casper Stake by Casper Punks</RefText>
        <div className="d-flex flex-row gap-5">
          <a href='https://discord.com/invite/casperpunks' target='_blank'>
            <ImgContact src={DiscordLogo} />
          </a>
          <a href='https://twitter.com/casperpunks_' target='_blank'>
            <ImgContact src={TwitterLogo} />
          </a>
          <a href='https://t.me/casperpunks' target='_blank'>
            <ImgContact src={TelegramLogo} />
          </a>
          <a href='https://medium.com/@casperpunks' target='_blank'>
            <ImgContact src={MLogo} />
          </a>
        </div>
      </FooterContact>
    </FooterWrapper>
  )
}

export default Footer
