import { useState, useEffect } from "react";
import {
  SectionWrapper,
  ContentWrapper,
  Title,
  Balance,
  LabelText,
  InputStyled,
  ButtonStyled,
  FormSection,
  MaxButton,
  RowBetween,
  InputDiv,
} from "../Stake/Styled";
import {
  useAccount,
  useWalletProvider,
  useConnectorId,
} from "state/wallet/hooks";
import { NATIVE_TOKEN_ADDERSS } from "../../constants";
import { useActiveWeb3React, useCurrentNetwork } from "hooks";
import { useCSPRBalance } from "hooks/useBlance";
import {
  CasperClient,
  CLByteArray,
  CLPublicKey,
  CLValueBuilder,
  DeployUtil,
  RuntimeArgs,
} from "casper-js-sdk";
import BigNumber from "bignumber.js";
import getConfig from "config/config";
import ConnectModal from "components/ConnectButton/ConnectModal";
import formatNumber, {
  getDeployFunction,
  getStakedAmount,
  getUndelegatedAmount,
  waitForDeployExecution,
} from "utils";
import { useTransactionAdder } from "state/transactions/hooks";
import { Buffer } from "buffer";
import { debounce } from "lodash";
import { useWalletConnect } from "state/modal/useWalletConnect";
import { ToastPromise } from "components/Toast";
import { useStakeInfo, useRewardInfo } from "hooks/useStakeInfo";
import axios from "axios";
import { useCSPRPrice } from "hooks/useCSPRPrice";

function UndelegatePage(): JSX.Element {
  const account = useAccount();
  const currentNetwork = useCurrentNetwork();
  const provider = useWalletProvider();
  const connectorId = useConnectorId();
  const { connector } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();
  const { signDeploy: walletConnectSignDeploy } = useWalletConnect(false);
  const csprPriceCallback = useCSPRPrice();

  const [amount, setAmount] = useState<string | number>();
  const [balance, setBalance] = useState<number | string>();
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [validator, setValidator] = useState<string>(getConfig().validator);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [textButton, setTextButton] = useState<string>("Enter amount");
  const [totalStake, setTotalStake] = useState<string | number>(0);
  const [totalReward, setTotalReward] = useState<string | number>(0);
  const [marketCap, setMarketCap] = useState<any>(0);
  const [csprPrice, setCSPRPrice] = useState<any>(0);
  const [userStakedAmount, setUserStakedAmount] = useState<any>(0);
  const [undelegatedAmount, setUndelegatedAmount] = useState<any>(0);

  const fetchSupply = async () => {
    const responseSupply = await axios.get(
      `${getConfig().makeAPI}/supply?is_cached=1`
    );
    if (responseSupply.data) {
      const _marketCap = (responseSupply.data.data.total * csprPrice).toFixed(
        2
      );
      setMarketCap(_marketCap);
    }
  };

  const fetchPrice = async () => {
    const _price = await csprPriceCallback();
    setCSPRPrice(_price);
  };

  useEffect(() => {
    fetchPrice();
  }, []);

  useEffect(() => {
    fetchSupply();
  }, [csprPrice]);

  const balanceCallback = useCSPRBalance(account);

  const loadStakeInfo = async () => {
    const respondStake = await axios.get(
      `${getConfig().makeAPI}/auction-validators/${
        getConfig().validator
      }?fields=account_info,average_performance`
    );
    const stakeInfo = respondStake.data.data;
    const respondReward = await axios.get(
      `${getConfig().makeAPI}/validators/${getConfig().validator}/total-rewards`
    );
    const reward = respondReward.data.data;

    if (account) {
      getStakedAmount(account).then((_userStakedAmount) => {
        setUserStakedAmount(_userStakedAmount);
      });
      {
        const _undelegatedAmount = await getUndelegatedAmount(account);
        setUndelegatedAmount(_undelegatedAmount);
      }
    }

    const _totalStake = new BigNumber(Number(stakeInfo?.total_stake))
      .dividedBy(10 ** 9)
      .toFixed(2);
    const _totalReward = new BigNumber(Number(reward))
      .dividedBy(10 ** 9)
      .toFixed(2);

    setTotalStake(_totalStake);
    setTotalReward(_totalReward);
  };

  useEffect(() => {
    loadStakeInfo();
  }, [account]);

  const loadTokenBalance = async () => {
    const _tokenBalance = await balanceCallback();

    const _balance = new BigNumber(_tokenBalance).dividedBy(10 ** 9).toString();
    setBalance(_balance);
  };

  useEffect(() => {
    loadTokenBalance();
  }, [account]);

  useEffect(() => {
    const handleStatusChange = async () => {
      console.log("amount", amount);
      const _amount = Number(amount);
      const csprBalance = Number(userStakedAmount);
      if (_amount < 500) {
        setTextButton("Requires 500 CSPR minimum");
        setDisabledButton(true);
      }
      if (amount === "") {
        setTextButton("Enter Amount");
        setDisabledButton(true);
      }
      if (_amount > csprBalance) {
        setTextButton("Insufficient Balance");
        setDisabledButton(true);
      }
      if (_amount >= 500 && _amount <= csprBalance) {
        setTextButton("Unstake");
        setDisabledButton(false);
      }
    };

    const debouncedHandleStatusChange = debounce(handleStatusChange, 300);

    debouncedHandleStatusChange();
    return () => {
      debouncedHandleStatusChange.cancel();
    };
  }, [amount, balance]);

  const onUnstake = async () => {
    const gasFee = 2500000000;
    try {
      if (account) {
        const senderKey = CLPublicKey.fromHex(account);
        const deployParams = new DeployUtil.DeployParams(
          senderKey,
          getConfig().network ?? "casper-test",
          1,
          1800000
        );
        const contractHashAsByteArray = Uint8Array.from(
          Buffer.from(getConfig().contracts.stakingContract!, "hex")
        );
        const _amount = new BigNumber(amount!).multipliedBy(10 ** 9).toString();

        console.log("amount: ", _amount);

        const runtimeArgs = RuntimeArgs.fromMap({
          delegator: senderKey,
          validator: CLPublicKey.fromHex(validator),
          amount: CLValueBuilder.u512(_amount),
        });

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            "undelegate",
            runtimeArgs
          ),
          DeployUtil.standardPayment(gasFee)
        );

        const deployJson = DeployUtil.deployToJson(deploy);

        console.log("deploy: ", deploy);
        console.log("deployJson: ", deployJson);

        if (deploy && provider) {
          const casperClient = new CasperClient(getConfig().nodeURL);

          const hash = await getDeployFunction(
            account,
            casperClient,
            connectorId,
            deploy,
            provider,
            deployJson,
            connector,
            walletConnectSignDeploy
          );
          if (hash) {
            console.log("hash: ", hash);
            addTransaction(hash, {
              summary: `Undelegate stake ${amount} CSPR`,
            });
            setAmount(0);
          }

          await ToastPromise(waitForDeployExecution(casperClient, hash), {
            pending: "Wait for deploying transaction",
            success: "Deploy success. Check your transaction in wallet button",
            error: {
              render({ data }) {
                console.log("error: ", data);
              },
            },
          });
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <SectionWrapper className="row justify-content-center">
      <div className="col-md-12 col-lg-6">
        <div
          style={{
            padding: "2rem",
            borderRadius: "12px",
            border: "1px solid #0000000D",
          }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <Title>Undelegate Stake</Title>
            <Balance>Staked Balance: {userStakedAmount} CSPR</Balance>
          </div>

          <FormSection style={{ margin: "5rem 0rem 1rem" }}>
            <div>
              <LabelText>Amount</LabelText>
              <InputDiv>
                <InputStyled
                  value={amount}
                  placeholder="0"
                  type="number"
                  onChange={(e) => {
                    setAmount(
                      Number(e.target.value) < 0 ? "0" : e.target.value
                    );
                  }}
                />
                <MaxButton onClick={() => setAmount(userStakedAmount)}>
                  Max
                </MaxButton>
              </InputDiv>
            </div>
            <div>
              <LabelText>Validator</LabelText>
              <InputDiv>
                <InputStyled
                  value={validator}
                  style={{ color: "rgba(0, 0, 0, 0.56)", width: "100%" }}
                />
              </InputDiv>
            </div>
          </FormSection>
          {!account ? (
            <>
              <ButtonStyled onClick={() => setShowConnectModal(true)}>
                Connect Wallet
              </ButtonStyled>
              <ConnectModal
                show={showConnectModal}
                onHide={() => setShowConnectModal(false)}
              />
            </>
          ) : (
            <ButtonStyled onClick={onUnstake} disabled={disabledButton}>
              {textButton}
            </ButtonStyled>
          )}
        </div>
      </div>
      <div className="col-md-12 col-lg-5">
        <ContentWrapper>
          <Title>Staking Statistics</Title>
          <div
            className="d-flex flex-column gap-3"
            style={{ marginTop: "3rem" }}
          >
            <RowBetween>
              <Balance>Market cap: </Balance>
              <Balance>${formatNumber(marketCap)}</Balance>
            </RowBetween>
            <RowBetween>
              <Balance>Available to unstake: </Balance>
              <Balance>{formatNumber(userStakedAmount)} CSPR</Balance>
            </RowBetween>
            <RowBetween>
              <Balance>Undelegated: </Balance>
              <Balance>{formatNumber(undelegatedAmount)} CSPR</Balance>
            </RowBetween>
            <RowBetween>
              <Balance>Total staked: </Balance>
              <Balance>{formatNumber(totalStake)} CSPR</Balance>
            </RowBetween>
            <RowBetween>
              <Balance>Total rewards: </Balance>
              <Balance>{formatNumber(totalReward)} CSPR</Balance>
            </RowBetween>
          </div>
        </ContentWrapper>
      </div>
    </SectionWrapper>
  );
}

export default UndelegatePage;
