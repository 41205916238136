import { CasperServiceByJsonRPC } from 'casper-js-sdk'
import { ERROR_BLOCKCHAIN } from 'errors'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAccount, useChainId } from 'state/wallet/hooks'
import { useActiveWeb3React, useCurrentNetwork } from '../../hooks'
import { useAddPopup, useBlockNumber } from '../application/hooks'
import { AppDispatch, AppState } from '../index'
import { checkedTransaction, finalizeTransaction } from './actions'

export function shouldCheck(
  lastBlockNumber: number,
  tx: {
    addedTime: number
    receipt?: {
      /* */
    }
    lastCheckedBlockNumber?: number
  },
): boolean {
  if (!tx) return false
  if (tx.receipt) return false
  if (!tx.lastCheckedBlockNumber) return true
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber
  if (blocksSinceCheck < 1) return false
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2
  } else {
    // otherwise every block
    return true
  }
}

export default function Updater(): null {
  const { library } = useActiveWeb3React()
  const account = useAccount()
  const chainId = useChainId()
  const network = useCurrentNetwork()

  const lastBlockNumber = useBlockNumber()
  //console.log('lastBlockNumber', lastBlockNumber, chainId)
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector<AppState, AppState['transactions']>(_state => _state.transactions)

  const transactions = chainId && account && state[chainId] ? state[chainId][account] ?? {} : {}

  // show popup on confirm
  const addPopup = useAddPopup()

  useEffect(() => {
    if (!chainId || !lastBlockNumber || !network || !account) return
    const client = new CasperServiceByJsonRPC(network?.nodeURL)
    Object.keys(transactions)
      .filter(hash => shouldCheck(lastBlockNumber, transactions[hash]))
      .forEach(hash => {
        client
          .getDeployInfo(hash)
          .then(receipt => {
            console.log('receipt', receipt)
            if (receipt.execution_results.length > 0) {
              client.getBlockInfo(receipt.execution_results[0].block_hash).then(block => {
                console.log('block', block)
                dispatch(
                  finalizeTransaction({
                    account,
                    chainId,
                    hash,
                    receipt: {
                      blockHash: receipt.execution_results[0].block_hash,
                      blockNumber: block.block?.header.height ?? lastBlockNumber,
                      // @ts-ignore
                      contractAddress: receipt.deploy.session['StoredContractByHash']?.hash,
                      from: receipt.deploy.header.account,
                      status: receipt.execution_results[0].result.Success ? 1 : 0,
                      // @ts-ignore
                      to: receipt.deploy.session['StoredContractByHash']?.hash,
                      transactionHash: receipt.deploy.hash,
                      transactionIndex: 0,
                    },
                  }),
                )
                addPopup(
                  {
                    txn: {
                      hash,
                      success:
                        receipt.execution_results.length > 0 &&
                        receipt.execution_results[0].result.Success != undefined,
                      summary: transactions[hash]?.summary,
                      errorMessage:
                        (receipt.execution_results.length > 0 &&
                        receipt.execution_results[0].result.Failure != undefined
                          ? ERROR_BLOCKCHAIN[`${receipt.execution_results[0].result.Failure.error_message}`]
                            ? ERROR_BLOCKCHAIN[`${receipt.execution_results[0].result.Failure.error_message}`].message
                            : `${receipt.execution_results[0].result.Failure.error_message}`
                          : '') || '',
                    },
                  },
                  hash,
                )
              })
            } else {
              dispatch(checkedTransaction({ account, chainId, hash, blockNumber: lastBlockNumber }))
            }
          })
          .catch(error => {
            console.error(`failed to check transaction hash: ${hash}`, error)
          })
      })
  }, [chainId, network, library, transactions, lastBlockNumber, dispatch, addPopup])

  return null
}
