import { Offcanvas } from "react-bootstrap";
import {
  DropContentLink,
  MainMenu,
  MobileMenu,
  NavBarWrap,
  NavLink,
  StyledLink,
  StyledScrollLink,
} from "./Styled";
import MenuDropdown from "./MenuDropdown";

interface INavigationProps {
  darkMode?: boolean;
  isOpen?: boolean;
  onHide?: any;
  footer?: boolean;
}

function Navigation(props: INavigationProps): JSX.Element {
  const { darkMode, isOpen, onHide, footer } = props;

  return (
    <MainMenu>
      {footer ? (
        <NavBarWrap className="d-lg-flex flex-grow-1" footer={footer}>
          <ul className="main-menu list-unstyled">
            <li>
              <NavLink to="/termofuse" darkMode={darkMode}>
                Term Of Use
              </NavLink>
            </li>
            <li>
              <NavLink to="/policy" darkMode={darkMode}>
                Privacy Policy
              </NavLink>
            </li>
            <li>
              <NavLink to="/faq" darkMode={darkMode}>
                FAQ
              </NavLink>
            </li>
          </ul>
        </NavBarWrap>
      ) : (
        <NavBarWrap className="d-none d-lg-flex flex-grow-1" footer={footer}>
          <ul className="main-menu list-unstyled">
            <li>
              <StyledLink
                href="https://casperpunks.io/portfolio"
                darkMode={darkMode}
                target="_blank"
              >
                Redeem Mystery Box
              </StyledLink>
            </li>
            <li>
              <MenuDropdown title="Marketplace">
                <DropContentLink
                  href="https://casperpunks.io/gen0boxes"
                  target="_blank"
                >
                  Gen 0
                </DropContentLink>
                <DropContentLink
                  href="https://casperpunks.io/mysterybox"
                  target="_blank"
                >
                  Mystery Box
                </DropContentLink>
                <DropContentLink
                  href="https://casperpunks.io/gen1boxes"
                  target="_blank"
                >
                  Gen 1
                </DropContentLink>
              </MenuDropdown>
            </li>
            <li>
              <StyledLink
                href="https://shop.casperpunks.io/"
                darkMode={darkMode}
                target="_blank"
              >
                MERCH
              </StyledLink>
            </li>
            <li>
              <MenuDropdown title="Ecosystem">
                <DropContentLink
                  href="https://casperpunks.io/#about"
                  target="_blank"
                >
                  About
                </DropContentLink>
                <DropContentLink
                  href="https://casperpunks.io/#roadmap"
                  target="_blank"
                >
                  Roadmap
                </DropContentLink>
                <DropContentLink
                  href="https://casperpunks.io/faq"
                  target="_blank"
                >
                  Faq
                </DropContentLink>
              </MenuDropdown>
            </li>
            <li>
              <StyledLink
                href="https://docs.casperpunks.io/"
                darkMode={darkMode}
                target="_blank"
              >
                Docs
              </StyledLink>
            </li>
            <li>
              <NavLink to="/" darkMode={darkMode}>
                Staking
              </NavLink>
            </li>
            <li>
              <NavLink to="/connectwallet" darkMode={darkMode}>
                Create Wallet
              </NavLink>
            </li>
          </ul>
        </NavBarWrap>
      )}
      <Offcanvas show={isOpen} onHide={onHide}>
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          <MobileMenu>
            <li>
              <StyledLink href="/" darkMode={darkMode} market>
                Marketplace
              </StyledLink>
            </li>
            <li>
              <StyledLink
                href="https://casperpunks.io/#about"
                darkMode={darkMode}
              >
                About
              </StyledLink>
            </li>
            <li>
              <StyledLink
                href="https://casperpunks.io/#roadmap"
                darkMode={darkMode}
              >
                Roadmap
              </StyledLink>
            </li>

            <li>
              <StyledLink href="https://casperpunks.io/faq" darkMode={darkMode}>
                FAQ
              </StyledLink>
            </li>
            <li>
              <StyledLink
                href="https://docs.casperpunks.io/"
                darkMode={darkMode}
                target="_blank"
              >
                Docs
              </StyledLink>
            </li>
            <li>
              <StyledLink href="/connectwallet" darkMode={darkMode}>
                Create Wallet
              </StyledLink>
            </li>
          </MobileMenu>
        </Offcanvas.Body>
      </Offcanvas>
    </MainMenu>
  );
}

export default Navigation;
