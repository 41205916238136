import styled from "styled-components/macro";

export const SectionWrapper = styled.section`
  background: #161f2f;
  padding-top: 8rem;
  padding-bottom: 5rem;

  @media (min-width: 992px) {
    padding-top: 5rem;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 4rem;
`;

export const ContentTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #f5f5f5;
  margin-bottom: 1.5rem;
`;

export const ContentText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #f5f5f5;
  margin-bottom: 0;

  span {
    font-weight: 700;
  }

  a {
    font-weight: 700;
    color: #4592ae;
  }
`;

export const ContentList = styled.ul`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #f5f5f5;
  list-style-type: disc;
  margin-left: 1.5rem;
`;
