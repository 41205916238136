import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@dotoracle/web3-react-core";
import { torus } from "connectors";
import { useEffect } from "react";
import { NetworkContextName } from "../constants";

export const useActiveWeb3React = () => {
  const context = useWeb3React<Web3Provider>();
  const contextNetwork = useWeb3React<Web3Provider>(NetworkContextName);
  return context.active ? context : contextNetwork;
};

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export const useInactiveListener = (suppress = false) => {
  const { active, error, activate } = useWeb3React(); // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    // @ts-ignore
    const { ethereum } = window;

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(torus, undefined, true).catch((e) => {
          console.error("Failed to activate after chain changed", e);
        });
      };

      const handleAccountsChanged = (accounts: []) => {
        if (accounts.length > 0) {
          // eat errors
          activate(torus, undefined, true).catch((e) => {
            console.error("Failed to activate after accounts changed", e);
          });
        }
      };

      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
        }
      };
    }
    return undefined;
  }, [active, error, suppress, activate]);
};
