import CasperLogo from '../assets/images/TokenLogos/casperIcon.png'
import BTCLogo from '../assets/images/TokenLogos/btc.png'
import BUSDLogo from '../assets/images/TokenLogos/busd-logo.png'
import ETHLogo from '../assets/images/TokenLogos/Ethereum-logo.png'
import USDCLogo from '../assets/images/TokenLogos/usdc.png'

export default function getConfig(env: string | undefined = process.env.REACT_APP_NETWORK) {
  switch (env) {
    case 'testnet':
      return {
        network: 'casper-test',
        chainId: 96945816564243,
        explorerURL: 'https://testnet.cspr.live',
        nodeURL: 'https://testnet.casper-node.tor.us',
        makeAPI: 'https://event-store-api-clarity-testnet.make.services',
        validator: '017d9aa0b86413d7ff9a9169182c53f0bacaa80d34c211adab007ed4876af17077',
        apiURL: '',
        contracts: {
          stakingContract: '93d923e336b20a4c4ca14d592b60e5bd3fe330775618290104f9beb326db7ae2'
        },
        projectId: '7ca193f0eff28082b3f3df1cbb359b76',
      }
    default:
      return {
        network: "casper",
        chainId: 131614895977472,
        explorerURL: "https://cspr.live",
        nodeURL: "https://casper-node.tor.us",
        makeAPI: "https://event-store-api-clarity-mainnet.make.services",
        apiURL: "",
        validator:
          "01c00bd605e492d7e36140136ac71111eb00b2d010be22d84a02ac0971776583fb",
        contracts: {
          stakingContract:
            "ccb576d6ce6dec84a551e48f0d0b7af89ddba44c7390b690036257a04a3ae9ea",
        },
        projectId: "7ca193f0eff28082b3f3df1cbb359b76",
      };
  }
}
