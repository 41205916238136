import { useCallback } from 'react'
import { CasperClient, CLPublicKey } from 'casper-js-sdk'
import getConfig from 'config/config'
import { erc20ClassInstance } from 'utils/ERC20'

const CHAIN_NAME = getConfig().network
const RPC = getConfig().nodeURL

export const useBalance = (account: string | null, contract: string | null): (() => Promise<any>) => {
  return useCallback(async (): Promise<any> => {
    try {
      if (!account || !contract) {
        return
      }
      const erc20 = erc20ClassInstance(RPC, CHAIN_NAME)
      await erc20.setContractHash(contract)
      return await erc20.balanceOf(CLPublicKey.fromHex(account))
    } catch (error: any) {
      console.error(error)
      return '0'
    }
  }, [account, contract])
}

export const useCSPRBalance = (account: string | null | undefined): (() => Promise<any>) => {
  return useCallback(async (): Promise<any> => {
    let _balance = 0
    try {
      if (!account) {
        return 0
      }
      const casper = new CasperClient(RPC)
      const casperBalance = await casper.balanceOfByPublicKey(CLPublicKey.fromHex(account))
      _balance = casperBalance.toNumber()
      return _balance
    } catch (error: any) {
      console.error(error)
      return 0
    }
  }, [account])
}