import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { SUPPORTED_WALLETS } from '../../constants'
import { AppDispatch } from 'state'
import { useChainId, useConnectorId, useDeactivateCallback } from 'state/wallet/hooks'
import { clearAllTransactions } from 'state/transactions/actions'
import { useAllTransactions, isTransactionRecent } from 'state/transactions/hooks'
import { useCurrentNetwork } from 'hooks'
import { getExplorerLink } from 'utils'
import Modal from 'components/Modal'
import Transaction from './Transaction'
import Copy from './Copy'
import { BiLinkExternal } from 'react-icons/bi'
import Button from 'components/Button'
import ExternalLink from 'components/ExternalLink'
import { ConnectorNames } from 'connectors'
import { WALLETCONNECT_ACCOUNT, WALLETCONNECT_SESSION_TOPIC } from 'contants'

const InfoWrapper = styled.div`
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  padding: 2rem;
`

const WalletName = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000;
  font-family: Montserrat;
`

const AccountKey = styled.p`
  font-size: 16px;
  line-height: 28px;
  margin-top: 1rem;
  color: #000;
  font-family: Montserrat;
`

const AddressLink = styled(ExternalLink)`
  font-size: 0.825rem;
  color: #565660;
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;
  align-items: center;
  font-family: Montserrat;

  &:hover {
    color: #181920;
  }
  span + svg {
    display: none !important;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
  }
`

const TransactionListWrapper = styled.div`
  margin-top: 1rem;
`

const LowerSection = styled.div`
  text-align: center;
  padding: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  background-color: #e5e5e5;
  border-radius: 0.75rem;
  margin-top: 1rem;

  h5 {
    margin: 0;
    font-weight: 400;
    font-family: Montserrat;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    font-family: Montserrat;
  }
`

const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #565660;
  font-weight: 500;
  font-family: Montserrat;

  &:hover {
    text-decoration: underline;
    color: #181920;
  }
  &:focus {
    outline: none;
    text-decoration: underline;
  }
  &:active {
    text-decoration: none;
  }
`

interface IAccountModalProps {
  account: string
  show: boolean
  onHide: () => void
}

function AccountModal(props: IAccountModalProps): JSX.Element {
  const { account, show, onHide } = props

  const accountEllipsis = account ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}` : ''

  const connectorId = useConnectorId()
  const chainId = useChainId()
  const deactivate = useDeactivateCallback()
  const networkInfo = useCurrentNetwork()

  const dispatch = useDispatch<AppDispatch>()
  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort((a, b) => b.addedTime - a.addedTime)
  }, [allTransactions])

  const pendingTransactions = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmedTransactions = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  const formatConnectorName = () => {
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(k => SUPPORTED_WALLETS[k].connectorId === connectorId)
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    return <>{name && <WalletName>{`Connected with ${name}`}</WalletName>}</>
  }

  const renderTransactions = (transactions: string[]) => {
    return (
      <TransactionListWrapper>
        {transactions.map((hash, i) => {
          return <Transaction key={i} hash={hash} />
        })}
      </TransactionListWrapper>
    )
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  const connectorID = useConnectorId()
  const onLogout = async () => {
    // Clear data
    const connector = connectorID
    await deactivate()

    if (connector == ConnectorNames.WalletConnect) {
      window.localStorage.removeItem(WALLETCONNECT_ACCOUNT)
      window.localStorage.removeItem(WALLETCONNECT_SESSION_TOPIC)
    }
    onHide()
  }

  return (
    <Modal size="lg" show={show} title="Your Account" onHide={onHide}>
      <InfoWrapper>
        <div className="d-flex justify-content-between align-items-center">
          {formatConnectorName()}
          <div>
            <Button disabled={false} handleClick={() => onLogout()}>
              <span>Disconnect</span>
            </Button>
          </div>
        </div>
        <div className="d-flex justify-content-left align-items-center gap-1">
          <AccountKey>{account && accountEllipsis}</AccountKey>
          {account && (
            <Copy toCopy={account}>
              <span style={{ marginTop: '4px' }}>Copy</span>
            </Copy>
          )}
          {chainId && account && (
            <AddressLink href={getExplorerLink(account, 'address')}>
              <BiLinkExternal size={18} />
              <span style={{ marginTop: '4px' }}>View on explorer</span>
            </AddressLink>
          )}
        </div>
        {!!pendingTransactions.length || !!confirmedTransactions.length ? (
          <LowerSection>
            <div className="d-flex justify-content-between align-items-center">
              <p>Recent Transactions</p>
              <StyledLink onClick={clearAllTransactionsCallback}>Clear All</StyledLink>
            </div>
            {renderTransactions(pendingTransactions)}
            {renderTransactions(confirmedTransactions)}
          </LowerSection>
        ) : (
          <LowerSection>
            <p>Your transactions will appear here...</p>
          </LowerSection>
        )}
      </InfoWrapper>
    </Modal>
  )
}

export default AccountModal
