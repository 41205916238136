import { useState, useEffect } from "react";
import getConfig from "config/config";
import { useCSPRPrice } from "hooks/useCSPRPrice";
import { useNavigate } from "react-router-dom";
import {
  FirstSection,
  TextSection,
  TextLarge,
  SmallText,
  ImgStyled,
  ButtonStyled,
  SecondSection,
  StatSection,
  StatBox,
  StatItem,
  StatItemTitle,
  StatItemContent,
  MediumBlackText,
  MediumWhiteText,
  ChoiceTitle,
  ChoiceText,
  InstructionsSection,
  InstructionBox,
  InstructionItem,
  InstructionButton,
  InstructionItemDiv,
  InstructionItemText,
  LearnMoreSection,
  FormSection,
  InputStyled,
  customStylesOrderType,
  ThirdSection,
  Li,
  Ul,
} from "./Styled";
import Button from "components/Button";
import Gen1Img from "assets/images/icon/gen1-mosaic-1.png";
import IconKey from "assets/images/icon/key.png";
import IconIndustry from "assets/images/icon/industry.png";
import IconTrade from "assets/images/icon/icon-trade.png";
import IconVerify from "assets/images/icon/icon-verify.png";
import IconProfile from "assets/images/icon/Profile.png";
import IconCompany from "assets/images/icon/Work.png";
import IconEmail from "assets/images/icon/Message.png";
import ImageGroup from "assets/images/icon/Group-10.png";
import Select from "react-select";
import axios from "axios";
import formatNumber from "utils";
import "./styles.css";
const optionnDescribe = ["vjkdnsjvnd", "vdbsvbd", "hjvdsvdjs"];
const optionsValue = [100, 200, 300];

function HomePage(): JSX.Element {
  const route = useNavigate();
  const csprPriceCallback = useCSPRPrice();

  const [marketCap, setMarketCap] = useState<any>(0);
  const [csprPrice, setCSPRPrice] = useState<any>(0);

  const fetchSupply = async () => {
    const responseSupply = await axios.get(
      `${getConfig().makeAPI}/supply?is_cached=1`
    );
    if (responseSupply.data) {
      const _marketCap = (responseSupply.data.data.total * csprPrice).toFixed(
        2
      );
      setMarketCap(_marketCap);
    }
  };

  const fetchPrice = async () => {
    const _price = await csprPriceCallback();
    setCSPRPrice(_price);
  };

  useEffect(() => {
    fetchPrice();
  }, []);

  useEffect(() => {
    fetchSupply();
  }, [csprPrice]);

  return (
    <>
      <FirstSection className="row">
        <TextSection className="col-md-7 col-sm-12">
          <TextLarge>EARN CSPR</TextLarge>
          <TextLarge>STAKING REWARDS</TextLarge>
          <SmallText>
            Casper is a ‘future proofed’ decentralized public blockchain,
            powered by proof-of-stake (PoS) consensus algorithms.
          </SmallText>
          <ButtonStyled onClick={() => route("/stake")}>STAKE NOW</ButtonStyled>
        </TextSection>
        <div className="col-md-5 col-sm-0 d-lg-block d-sm-none">
          <ImgStyled src={Gen1Img} />
        </div>
      </FirstSection>

      <SecondSection>
        <div>
          <MediumBlackText>
            Embrace CasperPunks Staking – Elevate Your Staking Experience.
          </MediumBlackText>
          <ChoiceTitle>
            Unlock the Power of Staking with Casper with CasperPunks. We are
            dedicated to delivering top-tier validator services for Casper
            Proof-of-Stake blockchain. Our seasoned team is a dynamic blend of
            tech-savvy and business-oriented professionals, armed with years of
            invaluable experience within the blockchain and cryptocurrency
            domain. Our robust servers are powered by cutting-edge hardware and
            exclusively fueled by a Casper native team. Plus, we're here for you
            24/7, offering unwavering support through our dedicated discord
            community channel.
          </ChoiceTitle>
        </div>
      </SecondSection>
      <ThirdSection>
        <MediumWhiteText>Why Us:</MediumWhiteText>
        <div style={{ paddingLeft: 10 }}>
          <Ul>
            <Li>
              Empower Your Stake with CasperPunks: At CasperPunks, we're the
              professionals you've been searching for, delivering non-custodial
              validator services for Casper Proof-of-Stake blockchains.
            </Li>
            <Li>
              Dedicated Support - Your Staking Companion: We have a 24/7 active
              Discord channel where you can get to know fellow stakers and
              CasperPunk Digital Collectible holders.
            </Li>
            <Li>
              Low Commissions - Maximize Your Rewards: Say goodbye to high fees!
              CasperPunks offers incredibly competitive commissions, ensuring
              you keep more of your hard-earned rewards, all without
              compromising on performance or service quality.
            </Li>
            <Li>
              Reliable Uptime - Your Staking Peace of Mind: We take pride in our
              dedication to providing near 100% uptime, because your peace of
              mind is our top priority. Your stakes are in safe hands.
            </Li>
            <Li>
              Security First - Your Keys, Your Tokens: Staking directly with our
              validators means your assets are secure.
            </Li>
          </Ul>
        </div>
      </ThirdSection>
      <SecondSection>
        <div>
          <MediumBlackText>Why Wait Any Longer?</MediumBlackText>
          <ChoiceTitle>
            Don't hesitate any longer. Begin your staking journey today with
            CasperPunks and start reaping the rewards you deserve.
          </ChoiceTitle>
        </div>
      </SecondSection>
    </>
  );
}

export default HomePage;
