import styled from 'styled-components/macro'

export const SectionWrapper = styled.div`
    // display: flex;
    // flex-direction: column;
    // justify-content: start;
    // align-items: center;
    padding: 5rem 1rem;
    background-color: #fff;
    // gap: 1rem;

    // @media (min-width: 992px) {
    //     flex-direction: row;
    //     justify-content: center;
    //     align-items: start;
    // }
`
export const ContentWrapper = styled.div`
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid #0000000D;
    margin-top: 1rem;

    @media (min-width: 992px) {
        margin: 0px;
    }
`
export const Title = styled.p`
    font-family: Montserrat;
    font-weight: 600;
    font-size: 24px;
    color: #000;
`
export const Balance = styled.p`
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    color: #000;
`
export const LabelText = styled.p`
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    color: #000;
    margin-bottom: 5px;
`
export const InputStyled = styled.input`
    width: 90%;
    border-radius: 12px;
    font-size: 16px;
    color: #000;
    font-family: Montserrat;
    border: none;
    background-color: #fff;

    &:focus {
        outline: none;
    }
`
export const MaxButton = styled.button`
    background-color: #9A0D2F;
    color: #fff;
    font-family: Montserrat;
    border-radius: 12px;
    padding: 8px;
    border: none;
    font-size: 12px;
    font-weight: 600;
`
export const ButtonStyled = styled.button`
    width: 100%;
    background-color: #9A0D2F;
    color: #fff;
    font-family: Montserrat;
    height: 3rem;
    border: none;
    border-radius: 12px;
    margin-top: 3rem;
    font-weight: 600;
`
export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 5rem 0rem;
`
export const RowBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const InputDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 1px solid black;
    height: 3rem;
    padding: 0.5rem 1rem;
`
export const RowCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`