import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import {
  ButtonWrapper,
  HeaderMain,
  HeaderWrapper,
  TitleWapper,
  MenuToggle,
  NavBarWrap,
  NavLink,
  NavLink2,
  StyledWalletIcon,
  HeaderMobile,
  MobileLogo,
} from "./Styled";
import LogoLight from "assets/images/logo-light.svg";
import ConnectButton from "components/ConnectButton";
import { FiMenu } from "react-icons/fi";

function HeaderSecond(): JSX.Element {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  return (
    <HeaderMain>
      <HeaderWrapper>
        <TitleWapper>
          <span>Staking</span>
        </TitleWapper>
        <NavBarWrap className="d-none d-lg-flex flex-grow-1">
          <ul className="main-menu list-unstyled">
            <li>
              <NavLink to="/undelagate-stake">Undelegate</NavLink>
            </li>
            <li>
              <NavLink to="/stake">Staking</NavLink>
            </li>
            <li>
              <NavLink to="/termsofservice">Terms of service</NavLink>
            </li>
          </ul>
          <ButtonWrapper>
            <ConnectButton />
          </ButtonWrapper>
          <StyledWalletIcon size={24} />
        </NavBarWrap>
      </HeaderWrapper>
      <HeaderMobile className="container">
        <MenuToggle onClick={handleMobileMenuOpen}>
          <FiMenu size={24} />
        </MenuToggle>
        <MobileLogo src={LogoLight} />
        <ConnectButton />
        <Offcanvas
          show={mobileMenuOpen}
          onHide={() => setMobileMenuOpen(false)}
        >
          <Offcanvas.Header closeButton />
          <Offcanvas.Body>
            <ul className="main-menu list-unstyled">
              <li>
                <NavLink2 href="/undelagate-stake">Undelegate</NavLink2>
              </li>
              <li>
                <NavLink2 href="/stake">Staking</NavLink2>
              </li>
              <li>
                <NavLink2 href="/termsofservice">Terms of service</NavLink2>
              </li>
              <li>
                <NavLink2 href="https://casperpunks.io/portfolio">
                  Redeem Mystery Box
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="https://casperpunks.io/portfolio">
                  My Portfolio
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="https://casperpunks.io/#about" target="_blank">
                  About
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="https://shop.casperpunks.io/" target="_blank">
                  MERCH
                </NavLink2>
              </li>
              <li>
                <NavLink2
                  href="https://casperpunks.io/#roadmap"
                  target="_blank"
                >
                  Roadmap
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="https://casperpunks.io/" target="_blank">
                  Marketplace
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="https://casperpunks.io/faq" target="_blank">
                  FAQ
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="https://docs.casperpunks.io/" target="_blank">
                  Docs
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="/">Staking</NavLink2>
              </li>
              <li>
                <NavLink2 href="/connectwallet">Create Wallet</NavLink2>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </HeaderMobile>
    </HeaderMain>
  );
}

export default HeaderSecond;
