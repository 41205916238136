import styled from "styled-components/macro";

export const FirstSection = styled.div`
  position: relative;
  background-color: #9a0d2f;
`;

export const TextSection = styled.div`
  padding: 5rem 2rem;
  display: flex;
  flex-direction: column;
  background-color: #9a0d2f;

  @media (min-width: 992px) {
    padding: 5rem 1rem 0rem 10rem;
  }
`;

export const TextLarge = styled.h2`
  font-family: Gamer;
  font-weight: 500;
  font-size: 6rem;
  color: #fff;
`;

export const SmallText = styled.p`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
`;
export const Li = styled.li`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  line-height: 30px;
  margin-right: 10px;
`;
export const Ul = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 10px;
  text-indent: -0.825em;
`;

export const ButtonStyled = styled.button`
  width: 10rem;
  background-color: #fff;
  padding: 1rem;
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 16px;
  color: #9a0d2f;
  border: none;
  margin-top: 5rem;
`;
export const ImgStyled = styled.img`
  display: none;

  @media (min-width: 992px) {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
export const SecondSection = styled.div`
  position: relative;
  padding: 5rem 1rem;
  background: #fff;

  @media (min-width: 992px) {
    padding: 5rem 10rem;
  }
`;
export const ThirdSection = styled.div`
  position: relative;
  padding: 5rem 1rem;
  background: rgb(22, 31, 47);

  @media (min-width: 992px) {
    padding: 5rem 10rem;
  }
  ul li:before {
  content: "•";
  padding-right: 10px;
  font-size: 20px;
  font-weight: bold;
`;

export const StatSection = styled.div`
  height: 5rem;
  background: #fff;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;
export const StatBox = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: row;
  background: #fff;
  box-shadow: 0px 4px 20px 0px #0000001a;
`;
export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const StatItemTitle = styled.p`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  color: #0f1723;
`;
export const StatItemContent = styled.p`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 40px;
  color: #0f1723;
`;
export const MediumWhiteText = styled.p`
  font-family: Gamer;
  font-weight: 500;
  font-size: 4rem;
  color: #fff;
`;
export const MediumBlackText = styled.p`
  font-family: Gamer;
  font-weight: 500;
  font-size: 4rem;
  color: #0f1723;
`;

export const ChoiceTitle = styled.p`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #0f1723;
  text-transfrom: uppercase;
  margin: 1rem 0rem;
`;
export const ChoiceText = styled.p`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: #0f1723;
`;
export const InstructionsSection = styled.div`
  padding: 10rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  background: #0f1723;

  @media (min-width: 992px) {
    padding: 10rem;
  }
`;
export const InstructionBox = styled.div`
  padding: 1rem;
  background: #ffffff0d;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const InstructionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;
export const InstructionButton = styled.button`
  padding: 1rem 1.5rem;
  background-color: #9a0d2f;
  color: #fff;
  font-weight: 700;
  font-family: Montserrat;
  fonr-size: 16px;
  text-transform: uppercase;
  border: none;
`;
export const InstructionItemDiv = styled.div`
  width: 28px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #9a0d2f;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 20px;
`;
export const InstructionItemText = styled.p`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
`;
export const LearnMoreSection = styled.div`
  padding: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background: #fff;
`;
export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-top: 1.5rem;
`;
export const InputStyled = styled.div`
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background-color: #0000000d;

  input {
    border: none;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    color: #0f1723;
    padding: 10px;
    background-color: transparent;

    &:hover {
      outline: none;
    }
  }
`;
export const customStylesOrderType = {
  container: (provided) => ({
    ...provided,
    background: "transparent",
    fontSize: 14,
    fontFamily: "Montserrat",
    color: "#0f1723",
  }),
  control: (control, state) => ({
    ...control,
    background: "#0000000d",
    border: "none",
    borderWidth: "1px",
    height: "56px",
    // minWidth: '185px',
    color: "#0f1723",
    justifyContent: "center",
    padding: "0.5rem",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: "#0000000d",
    color: "#0f1723",
    cursor: isDisabled ? "not-allowed" : "default",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#0f1723",
  }),
  menu: (base) => ({
    ...base,
    marginTop: 0,
    backgroundColor: "#0000000d",
  }),
};
