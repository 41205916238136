import { Container } from "react-bootstrap";
import {
  ContentList,
  ContentText,
  ContentTitle,
  SectionWrapper,
  TitleWrapper,
} from "./Styled";
import styled from "styled-components/macro";
import "./style.css";
export const SectionTitle = styled.h2`
  position: relative;
  font-family: "Gamer", serif;
  font-weight: 500;
  font-size: 63px;
  line-height: 28px;
  color: #ecb049;
  text-transform: uppercase;
  z-index: 2;

  span {
    color: #fff;
  }

  @media (min-width: 768px) {
    font-size: 80px;
    line-height: 35px;
  }

  @media (min-width: 1920px) {
    font-size: 103px;
    line-height: 44px;
  }
`;
function TermOfUse(): JSX.Element {
  return (
    <SectionWrapper>
      <Container>
        <TitleWrapper>
          <SectionTitle>Terms Of Service</SectionTitle>
        </TitleWrapper>
        <ContentTitle>Casperpunks Site Terms</ContentTitle>
        <ContentText>
          Welcome to CasperPunks Staking! These Terms of Service ("Terms" or
          "Agreement") outline and govern the exceptional services offered by
          CasperPunks Staking ("Company") to you and operated by CasperPunks PTE
          LTD, our valued user ("User").
          <br />
          <br />
          By entrusting your Tokens to Validator Nodes operated by CasperPunks
          Staking, you signify your consent to be bound by the terms outlined in
          this Agreement. If, for any reason, you find yourself in disagreement,
          confusion, or unable to comply with any part of this Agreement, we
          kindly request that you refrain from Staking your Tokens with
          Validator Nodes operated by Company.
          <br />
          <br />
          We strongly urge you to read through this Agreement carefully before
          Staking your Tokens to Validator Nodes operated by CasperPunks
          Staking. Please note that no formal signatures from either party are
          required for this Agreement to have full legal effect, and it becomes
          legally binding on the Effective Date (as defined below).
          <br />
          <br />
          If you have any questions or encounter any issues, we encourage you to
          reach out to us via email at
          <span style={{ color: "#0d6efd", fontSize: 15 }}>
            {" "}
            punk@casperpunks.io
          </span>{" "}
          or join our vibrant Discord group by following the link at{" "}
          <a
            href="https://discord.com/invite/casperpunks"
            style={{ textDecoration: "underline", color: "rgb(13, 110, 253)" }}
            target="_blank"
          >
            https://discord.com/invite/casperpunks
          </a>{" "}
          for prompt assistance.
          <br />
          <br />
          In the spirit of mutual understanding, Company and User hereby agree
          to the following:
          <br />
          <br />
        </ContentText>

        <ContentText>
          <span>1. Definitions</span>
          <br />
          <br />
          In these Terms, you'll find capitalized terms that carry specific
          meanings, either as defined within this section or elsewhere in this
          Agreement. Singular terms hold their respective plural forms and vice
          versa. As used in these Terms:
          <br />
          <br />
          Your continued use of the Website following the posting of revised
          Terms of Use means that you accept and agree to the changes. You are
          expected to check this page frequently so you are aware of any
          changes, as they are binding on you.
          <br />
          <br />
        </ContentText>
        <ContentList>
          <li>
            "Active Set" refers to those Validator Nodes with a sufficient stake
            of Tokens on the Network, enabling them to actively participate in
            consensus block production.
          </li>
          <li>
            "Applicable Law" encompasses all relevant national, provincial,
            international, federal, state, county, and local statutes, laws,
            ordinances, regulations, rules, codes, and orders issued by any
            governmental authority.
          </li>
          <li>
            "Blockchain" denotes a digital ledger used to chronicle transactions
            made with Cryptocurrency.
          </li>
          <li>
            "Cryptocurrency" or "Token" signifies a digital currency in which
            transactions are verified and records are maintained via a
            decentralized system, utilizing cryptography rather than a
            centralized authority.
          </li>
          <li>
            "Channels" encompass Company's website, Telegram Group, and Twitter.
          </li>
          <li>
            "Effective Date" represents the date on which you Stake your Tokens
            to a Validator Node operated by Company.
          </li>
          <li>
            "Governmental Agencies" embrace any nation or government, state or
            political subdivision, and any entity exercising governmental
            functions, including government authorities, agencies, departments,
            boards, commissions, and more.
          </li>
          <li>
            "Network" designates any PoS-blockchain on which Company operates a
            Validator Node.
          </li>
          <li>
            "Party" refers to either Company or User, while "Parties"
            collectively signifies both Company and User.
          </li>
          <li>
            "Rewards" or "Staking Rewards" indicate any Tokens granted by the
            Network to the Validator Node as a result of Validation Operations,
            including, but not limited to, block rewards and transaction fees
            received by Company or User in connection with the performance of
            Validation Operations.
          </li>
          <li>
            "Service Fees" or "Commission" embody the amount paid to Validator
            Nodes by Networks for providing Validation Operations on the
            Network, typically expressed as a percentage of the total Rewards
            and Transaction Fees earned.
          </li>
          <li>
            "Slashing Penalty" refers to any penalty applied by the Network to a
            Validator Node's staked Tokens due to unavailability or slow,
            incorrect, or malicious performance.
          </li>
          <li>
            Staking," "Staked," "Stakes," or "to Stake" denotes the act of
            committing Tokens to a Validator Node to support the Network's
            security through Validator Node's Validation Operations.
          </li>
          <li>
            "Stake" signifies the total amount of Tokens Staked by you to a
            Validator Node operated by Company.
          </li>
          <li>
            "Services" involve offering Validation Operations and exercising
            Voting Rights by Company on behalf of User.
          </li>

          <li>
            "Discord Group" designates Company's Discord group, accessible at{" "}
            <a
              href="https://discord.com/invite/casperpunks"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              https://discord.com/invite/casperpunks
            </a>
          </li>

          <li>
            "Telegram Group" designates Company's Telegram group, accessible at{" "}
            <a
              href="https://t.me/casperpunks"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              {" "}
              https://t.me/casperpunks.
            </a>
          </li>
          <li>
            "Transaction Fees" encompass any amount of digital tokens paid to
            the Network by users to execute transactions, a portion of which may
            be distributed to Validator Nodes, Users, or both.
          </li>
          <li>
            "Twitter" relates to Company's Twitter account, accessible at
            <a
              href="https://twitter.com/casperpunks"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              {" "}
              https://twitter.com/casperpunks.
            </a>
          </li>
          <li>
            "Unstaking" involves removing tokens from a Validator Node operated
            by Company or ceasing the act of Staking.
          </li>
          <li>
            "User" represents a holder of Tokens who Stakes Tokens with a
            Validator Node operated by Company.
          </li>
          <li>
            "Validator Node" stands for a full node participating in "consensus"
            on the Network, responsible for verifying, voting on, and
            maintaining a record of transactions on a Blockchain Network.
          </li>
          <li>
            "Validation Operations" refers to operating a Validator Node to
            which Users can Stake.
          </li>
          <li>
            "Voting Rights" signify the rights you gain by Staking to a
            Validator Node, indirectly allowing you to participate in Network
            governance based on the number of tokens staked.
          </li>
          <li>
            "Website" symbolizes Company's website, accessible at
            <a
              href="https://casperpunks.io"
              style={{ textDecoration: "underline" }}
              target="_blank"
            >
              {" "}
              https://casperpunks.io.
            </a>
          </li>
          <br />
          <br />
        </ContentList>

        <ContentText>
          <span>2. Services and Code of Conduct</span>
          <br />
          <br />
          Subject to these Terms, where Company operates Validator Nodes among
          the Active Set, Company commits to operating them in line with
          industry standards.
          <br />
          <br />
          During the operation of Validator Nodes, Company will:
          <br />
          <ContentList>
            <li>
              Implement industry-standard monitoring and alerting tools to
              minimize downtime and slashing penalties.
            </li>
            <li>
              Take reasonable steps to safeguard all private and validator keys
              essential for operation.
            </li>
            <li>
              Maintain open lines of communication between Users and Company's
              team. Strive to provide valuable services, infrastructure, and
              offerings to the Network's communities.
            </li>
            <li>
              Share its position on Governance proposals and exercise Voting
              Rights based on Users' preferences.
            </li>
            <li>
              Announce changes in Service Fees at least one week in advance
              through official Channels.
            </li>
            <li>
              Notify Users of the termination of Validation Operations for a
              specific Network at least one month ahead via official Channels.
            </li>
            <li>
              Offer reimbursement for missed Rewards, excluding force majeure
              situations, when Rewards are not received by Users for a period
              exceeding the durations mentioned below:
              <li>Casper Network: longer than 2 ERAs or 4 hours.</li>
              <li>
                Provide Users with the aforementioned reimbursement up to a
                maximum equivalent to 1 week of Rewards.
              </li>
            </li>
            <br />
          </ContentList>
          It's crucial to understand that there is no guarantee or assurance
          that Company will operate Validator Nodes at all or for any specific
          period. Company's failure to enter or remain in the Active Set or
          provide Validation Operations does not constitute a breach of these
          Terms or impose any liability or obligation on Company towards Users.
          <br />
          <br />
          Company holds no obligation to perform Validation Operations if the
          following conditions are not satisfied:
          <ContentList>
            <li>
              The representations and warranties outlined in Section 3 by User
              remain accurate and complete after agreeing to these Terms and
              Staking with a Validator Node operated by Company.
            </li>
            <li>Company is included in the Active Set on the Network.</li>
            <li>
              Neither User's Stake nor Company's Validation Operations would
              breach any Law, contract, or agreement applicable to User or
              Company.
            </li>
            <br />
            <br />
          </ContentList>
          Please note that, due to the decentralized design of Networks, anyone
          holding Tokens can anonymously Stake with Validator Nodes operated by
          Company. Company often lacks the ability to prevent such Staking under
          ordinary circumstances. Moreover, Company may be unable to obtain
          critical information about Users.
          <br />
          <br />
          If you suspect that any of these conditions are not met before Staking
          to Validator Nodes operated by Company or if you realize they are no
          longer satisfied after Staking, please cease Staking your Tokens
          immediately.
          <br />
          <br />
          <br />
        </ContentText>

        <ContentText>
          <span>3. User Representations and Warranties</span>
          <br />
          <br />
          As you enter into this Agreement and Stake your Tokens with Validator
          Nodes operated by Company, you hereby represent and warrant to
          Company, on the date of Agreement and throughout your Staking period:
          <br />
          <br />
          3.1 If you are an individual, you are of legal age in your residing
          jurisdiction, sound in mind and body. If you represent a business
          entity, it is duly organized, validly existing, and in good standing
          under the laws of its jurisdiction, possessing the requisite power and
          authority to conduct its business as currently practiced.
          <br />
          <br />
          3.2 You possess the capacity, power, and authority to accept these
          Terms and fulfill your obligations as stated. These Terms constitute a
          legal, valid, and binding commitment enforceable against you.
          <br />
          <br />
          3.3 Your entrance into this Agreement and your Staking to Validator
          Nodes operated by Company do not and would not reasonably be expected
          to result in any breach, default, infringement, or violation of any
          applicable Law, contract, or agreement.
          <br />
          <br />
          3.4 You are not owned or controlled by, or acting on behalf of, any
          person identified on any prohibited parties list under any Law or by
          any Governmental Agencies. Your tokens are not derived from activities
          that violate any Law.
          <br />
          <br />
          3.5 You understand and agree that your Staking to Validator Nodes
          operated by Company:
          <ContentList>
            <li>
              Does not confer ownership, control, or custody of your Tokens to
              Company.
            </li>
            <li>
              Does not constitute a loan, contribution of capital, or investment
              in Company.
            </li>
            <li>
              Does not provide you with an ownership interest, equity, security,
              or voting rights in Company.
            </li>
            <li>
              Does not create a fiduciary or agency relationship between
              Company, its directors, officers, employees, agents, and you.
            </li>
          </ContentList>
          <br />
          <br />
          3.6 You possess knowledge, experience, and sophistication in using and
          evaluating the Network and related technologies. You have conducted
          your independent investigation and analysis, independent of any
          information, statement, representation, or warranty from Company.
          <br />
          <br />
          3.7 You are solely responsible for the payment of any applicable taxes
          related to your share of Rewards and other taxes resulting from the
          transactions contemplated in this Agreement. Company does not provide
          tax advice or representation. You agree to defend, indemnify, and hold
          us and our officers, directors, managers, employees, and agents
          harmless from any tax-related liabilities, costs, and expenses.
          <br />
          <br />
        </ContentText>

        <ContentText>
          <span>4. Limitation of Use</span>
          <br />
          <br />
          These Terms govern your access and use of the Website and are an
          integral part of this Agreement. You acknowledge and agree that
          Company does not accept or take custody of any Rewards on your behalf
          and has no control over whether the Network distributes Rewards to
          you. Our sole obligation under this Agreement is to perform the
          Services as defined within these Terms.
          <br />
          <br />
        </ContentText>

        <ContentText>
          <span>5. No Consequential, Incidental, or Punitive Damages</span>
          <br />
          <br />
          Company shall not be liable to any person for special, incidental,
          consequential, indirect, punitive, or exemplary damages, regardless of
          the cause of action or theory asserted, and even if Company knew or
          was advised of the possibility of such damages.
          <br />
          <br />
        </ContentText>

        <ContentText>
          <span>6. Limitation of Liability</span>
          <br />
          <br />
          In no case shall Company's liability for damages exceed the portion of
          Commission received by Company arising directly from your particular
          Staking of Tokens to Validator Nodes operated by Company. CasperPunks
          uses the 3rd party services of Validation Cloud
          <a
            href="https://www.validationcloud.io/"
            style={{ textDecoration: "underline" }}
            target="_blank"
          >
            {" "}
            https://www.validationcloud.io/
          </a>{" "}
          to operate the Staking services mentioned in this terms of use and
          does not operate the staking directly. When you stake via the
          CasperPunks website you are interacting with the Validation Cloud
          managed infrastructure.
          <br />
          <br />
        </ContentText>
        <ContentText>
          <span>7. Indemnification</span>
          <br />
          <br />
          You agree to indemnify, defend, and hold Company, its partners,
          agents, officers, directors, and employees harmless from any Losses
          incurred due to third-party claims resulting from any breach by you of
          any representation, warranty, or provision in this Agreement. Company
          shall indemnify, defend, and hold you harmless from any Losses
          resulting from any breach by Company of any representation, warranty,
          or provision in this Agreement.
          <br />
          <br />
        </ContentText>

        <ContentText>
          <span>8. Term, Termination, and Suspension</span>
          <br />
          <br />
          The Services outlined in these Terms commence on the Effective Date.
          <br />
          <br />
          Either party may terminate these Terms:
          <br />
          <ContentList>
            <li>
              At any time, with or without reason, by delivering written notice
              of termination to the other Party, or
            </li>

            <li>
              For a good cause, with immediate effect, if the other Party
              materially breaches any provision of these Terms, subject to any
              restrictions on termination provided in the Network's terms and
              conditions.
            </li>
          </ContentList>
          Upon termination, you shall:
          <br />
          <ContentList>
            <li>Cease using the Services,</li>

            <li>Initiate Unstaking of your Tokens, and</li>
            <li>
              Settle all outstanding fees and expenses up to the termination
              date.
            </li>
          </ContentList>
          <br />
          Upon termination, Company shall pay you your attributable Rewards
          minus Commission. The termination of these Terms, for any reason, does
          not affect Company's entitlement to any fees and expenses due.
          <br />
          <br />
          Upon termination of these Terms, all licenses to access and use the
          Services will terminate, and you must discontinue all access and use
          immediately.
          <br />
          <br />
          Obligations and duties that naturally extend beyond the expiration or
          termination of these Terms will survive such events, including accrued
          rights to payment, warranty disclaimers, and limitations of liability.
          <br />
          <br />
          By acknowledging that you have read, understood, and agreed to be
          bound by this Agreement, you demonstrate your commitment to engaging
          with CasperPunks Staking under these Terms. Welcome to the CasperPunks
          Staking community!
          <br />
          <br />
        </ContentText>
      </Container>
    </SectionWrapper>
  );
}

export default TermOfUse;
