import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

import styled from "styled-components/macro";

export const MainMenu = styled.div`
  position: relative;
  flex: 1;
`;

export const NavBarWrap = styled.div<{ footer?: boolean }>`
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    justify-content: ${({ footer }) => (footer ? "center" : "flex-start")};
    width: 100%;
    margin-bottom: 0;

    @media (min-width: 768px) {
      justify-content: ${({ footer }) => (footer ? "flex-end" : "center")};
    }

    li {
      margin: 0 10px;

      @media (min-width: 1200px) {
        margin: 0 1rem;
      }

      @media (min-width: 1400px) {
        margin: 0 1.25rem;
      }
    }
  }
`;

export const StyledLink = styled.a<{ darkMode?: boolean; market?: boolean }>`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ darkMode, market }) =>
    market ? "#ad203e" : darkMode ? "#fff" : "#000"};

  &:hover {
    color: ${({ darkMode, market }) =>
      market ? "#ad203e" : darkMode ? "#fff" : "#000"};
    text-shadow: 0 0 1px ${({ darkMode }) => (darkMode ? "#fff" : "#000")};
    transition: all 0.5s ease;
    cursor: pointer;
  }
`;
export const NavLink = styled(Link)<{ darkMode?: boolean; market?: boolean }>`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ darkMode, market }) =>
    market ? "#ad203e" : darkMode ? "#fff" : "#000"};

  &:hover {
    color: ${({ darkMode, market }) =>
      market ? "#ad203e" : darkMode ? "#fff" : "#000"};
    text-shadow: 0 0 1px ${({ darkMode }) => (darkMode ? "#fff" : "#000")};
    transition: all 0.5s ease;
    cursor: pointer;
  }
`;

export const StyledScrollLink = styled(ScrollLink)<{
  darkMode?: boolean;
  market?: boolean;
}>`
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ darkMode, market }) =>
    market ? "#ad203e" : darkMode ? "#fff" : "#000"};

  &:hover {
    color: ${({ darkMode, market }) =>
      market ? "#ad203e" : darkMode ? "#fff" : "#000"};
    text-shadow: 0 0 1px ${({ darkMode }) => (darkMode ? "#fff" : "#000")};
    transition: all 0.5s ease;
    cursor: pointer;
  }
`;

export const MobileMenu = styled.ul`
  list-style: none;
`;
export const DropContentLink = styled.a`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-decoration: none;
  color: #000;

  &:hover {
    color: #000;
    text-shadow: 0 0 1px #000;
    transition: all 0.5s ease;
    cursor: pointer;
  }
`;
