import { CasperServiceByJsonRPC } from 'casper-js-sdk'
import { useActiveWeb3React, useCurrentNetwork, useDebounce, useIsWindowVisible } from 'hooks'
import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useChainId } from 'state/wallet/hooks'
import { updateBlockNumber } from './actions'

export default function Updater(): null {
  const { library } = useActiveWeb3React()
  const chainId = useChainId()
  const dispatch = useDispatch()
  const network = useCurrentNetwork()

  const windowVisible = useIsWindowVisible()

  const [state, setState] = useState<{ chainId: number | undefined; blockNumber: number | null }>({
    chainId,
    blockNumber: null,
  })

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      setState(_state => {
        if (chainId === _state.chainId) {
          if (typeof _state.blockNumber !== 'number') return { chainId, blockNumber }
          return { chainId, blockNumber: Math.max(blockNumber, _state.blockNumber) }
        }
        return _state
      })
    },
    [chainId, setState],
  )

  // attach/detach listeners
  useEffect(() => {
    const intervalId = setInterval(() => {
      //console.log('update')
      const client = new CasperServiceByJsonRPC(network ? network.nodeURL : '')
      client
        .getLatestBlockInfo()
        .then(block => {
          blockNumberCallback(block.block?.header.height ?? 0)
        })
        .catch(error => console.error(`Failed to get block number for chainId: ${chainId}`, error))
    }, 1000 * 20) // in milliseconds
    return () => clearInterval(intervalId)
    //windowVisible
  }, [dispatch, chainId, library, blockNumberCallback])

  const debouncedState = useDebounce(state, 100)
  //console.log('debouncedState', debouncedState)
  useEffect(() => {
    if (!debouncedState.chainId || !debouncedState.blockNumber || !windowVisible) return
    dispatch(updateBlockNumber({ chainId: debouncedState.chainId, blockNumber: debouncedState.blockNumber }))
  }, [windowVisible, dispatch, debouncedState.blockNumber, debouncedState.chainId])

  return null
}
