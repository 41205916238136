import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Web3ReactManager from "components/Web3ReactManager";
import Header from "components/Header";
import HeaderSecond from "components/HeaderSecond";
import Footer from "components/Footer";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/fonts/stylesheet.css";
import "assets/scss/style.scss";
import Popups from "components/Popups";

import HomePage from "pages/Home";
import StakePage from "pages/Stake";
import UndelegatePage from "pages/Undelegate";

import { ThemeProvider } from "styled-components/macro";
import { lightTheme, GlobalStyles } from "./themes";
import TermOfUse from "pages/TermOfUse";

// eslint-disable-next-line
function App() {
  return (
    <Web3ReactManager>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <Router>
          <Header></Header>
          <HeaderSecond />
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
          <Routes>
            <Route path="/stake" element={<StakePage />} />
          </Routes>
          <Routes>
            <Route path="/undelagate-stake" element={<UndelegatePage />} />
            <Route path="/termsofservice" element={<TermOfUse />} />
          </Routes>
          <Footer />
          <Popups />
        </Router>
      </ThemeProvider>
    </Web3ReactManager>
  );
}

export default App;
