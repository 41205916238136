import getConfig from 'config/config'

// get config
export const CHAIN_NAME = getConfig().network
export const CHAIN_ID = getConfig().chainId

export const RPC = getConfig().nodeURL

// end get config contract
export const API_URL = getConfig().apiURL

export const MIN_TRADE = 1
export const MIN_SWAP = 0
export const MIN_ADD_LIQUIDITY = 0
export const MIN_REMOVE_LIQUIDITY = 0

// default gas
export const SWAP_GAS = 50
export const ADD_LQ_GAS = 120
export const TRADE_GAS = 30

// end default gas

export const EXECUTION_FEE = 100

export const WALLETCONNECT_ACCOUNT = 'WALLETCONNECT_ACCOUNT'
export const WALLETCONNECT_SESSION_TOPIC = 'WALLETCONNECT_SESSION_TOPIC'
