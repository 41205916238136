import { PairingTypes, SessionTypes } from '@walletconnect/types'
import { useCallback, useEffect, useState } from 'react'
import SignClient from '@walletconnect/sign-client'
import { WalletConnectModal } from '@walletconnect/modal'
import getConfig from 'config/config'
import { WALLETCONNECT_ACCOUNT, WALLETCONNECT_SESSION_TOPIC } from '../../contants'
import { DeployUtil } from 'casper-js-sdk'

export const useWalletConnect = (showWalletConnectModal: boolean) => {
  const [pairings, setPairings] = useState<PairingTypes.Struct[]>([])
  const [session, setSession] = useState<SessionTypes.Struct>()
  const [account, setAccount] = useState<string>('')
  const [client, setClient] = useState<SignClient>()
  const chainId = 'casper:' + getConfig().network

  const disconnect = () => {
    window.localStorage.removeItem(WALLETCONNECT_ACCOUNT)
    window.localStorage.removeItem(WALLETCONNECT_SESSION_TOPIC)
  }

  useEffect(() => {
    if (showWalletConnectModal) {
      handleWalletConnect().then(() => {
        console.log('account updated')
      })
    }
  }, [showWalletConnectModal])

  const onSessionConnected = useCallback(async (_session: SessionTypes.Struct) => {
    let allNamespaceAccounts = Object.values(_session.namespaces)
      .map((namespace) => namespace.accounts)
      .flat()
    setSession(_session)
    allNamespaceAccounts = allNamespaceAccounts ? allNamespaceAccounts : []
    const account_ = allNamespaceAccounts[0]
    const [, , address] = account_.split(':')
    console.log('allNamespaceAccounts', _session)
    setAccount(address)
    window.localStorage.setItem(WALLETCONNECT_ACCOUNT, address)
    window.localStorage.setItem(WALLETCONNECT_SESSION_TOPIC, _session.topic)
  }, [])

  const signDeploy = async (deployJson) => {
    try {
      const signClient = await SignClient.init({
        projectId: getConfig().projectId,
        // optional parameters
        relayUrl: 'wss://relay.walletconnect.com',
        metadata: {
          name: 'CasperPunks Stake',
          description: 'CasperPunks Stake',
          url: '#',
          icons: ['https://walletconnect.com/walletconnect-logo.png'],
        },
      })
      const topic = window.localStorage.getItem(WALLETCONNECT_SESSION_TOPIC) || ""
      const result = await signClient.request({
        topic,
        chainId,
        request: {
          method: 'casper_sign_deploy',
          params: {
            deploy: deployJson,
            address: account,
          },
        },
      })
      const signedDeploy = DeployUtil.deployFromJson(result).unwrap()
      return signedDeploy
    } catch (e) {}
  }

  const reset = () => {
    setSession(undefined)
    setAccount('')
  }

  const chains = ['casper:casper', 'casper:casper-test']
  const handleWalletConnect = async () => {
    const walletConnectModal = new WalletConnectModal({
      projectId: getConfig().projectId || '',
      // `standaloneChains` can also be specified when calling `walletConnectModal.openModal(...)` later on.
      chains: chains,
    })
    
    const signClient = await SignClient.init({
      projectId: getConfig().projectId,
      // optional parameters
      relayUrl: 'wss://relay.walletconnect.com',
      metadata: {
        name: 'Casper Liquid Staking',
        description: 'Casper Liquid Staking',
        url: '#',
        icons: ['https://walletconnect.com/walletconnect-logo.png'],
      },
    })
    setClient(signClient)
    console.log('done init', signClient)

    signClient.on('session_event', (args) => {
      // Handle session events, such as "chainChanged", "accountsChanged", etc.
    })

    signClient.on('session_update', ({ topic, params }) => {
      const { namespaces } = params
      const _session = signClient.session.get(topic)
      // Overwrite the `namespaces` of the existing session with the incoming one.
      const updatedSession = { ..._session, namespaces }
      // Integrate the updated session state into your dapp state.
      onSessionConnected(updatedSession)
    })

    signClient.on('session_delete', () => {
      reset()
    })
    try {
      console.log('start connecting')
      const topic = window.localStorage.getItem(WALLETCONNECT_SESSION_TOPIC)
      const pairings = [topic]
      console.log('pairing', pairings)
      const requiredNamespaces = {}
      requiredNamespaces['casper'] = {
        methods: ['casper_sign_message', 'casper_sign_deploy'],
        chains,
        events: [],
      }
      const { uri, approval } = await signClient.connect({
        // Provide the namespaces and chains (e.g. `eip155` for EVM-based chains) we want to use in this session.
        requiredNamespaces,
      })
      // Open QRCode modal if a URI was returned (i.e. we're not connecting an existing pairing).
      if (uri) {
        walletConnectModal.openModal({ uri })
        // Await session approval from the wallet.
        const session = await approval()
        setPairings(signClient.pairing.getAll({ active: true }))

        console.log('session', session)
        // Handle the returned session (e.g. update UI to "connected" state).
        // * You will need to create this function *
        onSessionConnected(session)
        // // Close the QRCode modal in case it was open.
        walletConnectModal.closeModal()
      }
    } catch (e) {
      console.error('error:', e)
    }
    return signClient
  }
  return { pairings, session, account, client, signDeploy, disconnect }
}