import { useMemo } from "react";
import { useSelector } from "react-redux";
import networks from "config/networks.json";
import Network from "type/Network";
import { useChainId } from "state/wallet/hooks";
import { AppState } from "state";
import getConfig from "config/config";

export const useAllNetworks = (isTestnet?: boolean): Network[] => {
  return useMemo(() => {
    return (
      isTestnet
        ? networks.filter((n) => n.isTestnet)
        : networks.filter((n) => !n.isTestnet)
    ) as Network[];
  }, [isTestnet, networks]);
};

export const useNetworkInfo = () => {
  return useMemo(() => {
    return getConfig();
  }, [networks]);
};

export const useCurrentNetwork = (): any => {
  const currentNetwork = useNetworkInfo();

  return useMemo(() => currentNetwork, []);
};

export const useTargetNetwork = (): Network | undefined => {
  const targetNetwork = useSelector(
    (state: AppState) => state.application.targetNetwork
  );
  return useMemo(() => targetNetwork, [targetNetwork]);
};
